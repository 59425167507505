import * as React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import SimpleTable from './SimpleTable';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from 'material-ui';

import _ from 'lodash';

const styles = theme => ({
  root: {
    backgroundColor: 'green',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class CsvParser extends React.Component {
  constructor(props) {
    super(props);
  }
      
  render() {
    return (
      <MuiThemeProvider>
      <div className = "ParserResults">
        {this.props.isstub && <div className="results-stub"><b>Results will appear here:</b></div>}
        {!this.props.isstub && 
          <div className="results-filled-container">
            <div className="results-filled" align="left">
              Found {this.props.data.body.length} rows, {this.props.data.header.length} columns:
            </div>
            <div align="right">
              <a href={"http://" + window.backendHost + "/download?id=" + this.props.data.id}>
                Download as XLS</a></div>
          </div>
          }
        <Paper>
          <SimpleTable data={this.props.data} />
        </Paper>
      </div>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(CsvParser);
