import * as React from 'react';
import TextField from 'material-ui/TextField';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import SimpleTable from './SimpleTable';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { withStyles } from '@material-ui/core/styles';

import _ from 'lodash';
import { Paper } from 'material-ui';

const styles = theme => ({
  root: {
    backgroundColor: 'red',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class CsvExtractor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [{
        header: [{type: 'str', name: 'h1'}, {type: 'str', name: 'h2'}, {type: 'str', name: 'h3'}],
        body: [[{type: 'str', val: 'v1'}, {type: 'str', val: 'v2'}, {type: 'str', val: 'v3'}],
               [{type: 'str', val: 'v1'}, {type: 'str', val: 'v2'}, {type: 'str', val: 'v3'}],
               [{type: 'str', val: 'v1'}, {type: 'str', val: 'v2'}, {type: 'str', val: 'v3'}]
            ]},
      {
        header: [{type: 'str', name: 'H1'}, {type: 'str', name: 'H2'}, {type: 'str', name: 'H3'}],
        body: [[{type: 'str', val: 'V1'}, {type: 'str', val: 'v2'}, {type: 'str', val: 'v3'}],
               [{type: 'str', val: 'V1'}, {type: 'str', val: 'v2'}, {type: 'str', val: 'v3'}],
               [{type: 'str', val: 'V1'}, {type: 'str', val: 'v2'}, {type: 'str', val: 'v3'}]]
      }],
      isStub: true};
    this.debouncedParse = _.debounce(this.handleText, 100);
  }

  componentDidMount() {
      /*
    fetch('http://127.0.0.1:5000/stub')
      .then(results => {
        return results.json();
      })
      .then(data => {
        this.setState({ data: data });
      });
      */
  }

  handleText(e) {
    const value = e.target.value;
    const backendUrl = window.backendHost + '/extract';
    fetch(backendUrl, {
      method: 'post',
      body: value
    })
      .then(results => {
        return results.json()
      })
      .then(data => {
        console.log(data);
        this.setState({ data: data, isStub: false });
      });
  };

  textCallback(e) {
    e.persist();
    this.debouncedParse(e);
  }

  render() {
    return (
      <MuiThemeProvider>
      <div className="CsvExtractor">
      <h2>Text parsing powered by AI</h2>
      <b>Have a document that contains structured data?  We use artificial intelligence to extract the data, clean
        it up, and let you download it as a csv or xlsx file.</b>
        <br></br><br></br>
        <b>To get started paste the raw text below:</b>
        <TextField
          id="outlined-textarea"
          floatingLabelText="Paste some text here"
          fullWidth={true}
          multiLine={true}
          rows={5}
          variant="outlined"
          margin="normal"
          onChange={this.textCallback.bind(this)}
        />
        {this.state.isStub && <div>Results will appear here:</div>}
        {!this.state.isStub && <div>Found {this.state.data.length} results!</div>}
        {(this.state.data.map((d, tableindex) => {
            return (
                <div>
                <b>Table #{tableindex+1}</b>
                <Paper>
                    <SimpleTable data={d}/>
                </Paper>
                </div>
            );
        }))}
      </div>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(CsvExtractor);
