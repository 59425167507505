import * as React from 'react';
import TextField from 'material-ui/TextField';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import ParserResults from './ParserResults';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from 'material-ui';

import _ from 'lodash';

const styles = theme => ({
  root: {
    backgroundColor: 'green',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class CsvParser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        id: null,
        header: [],
        body: [],
      },
      isstub: true,
    };
    this.addCol = this.addCol.bind(this);
    this.removeCol = this.removeCol.bind(this);
    this.debouncedParse = _.debounce(this.handleText, 1000);
    //this.handleText = this.handleText.bind(this);
  }

  addCol() {
    this.setState(state => ({
      numCols: state.numCols + 1
    }));
  }

  removeCol() {
    this.setState(state => ({
      numCols: state.numCols - 1
    }));
  }

  componentDidMount() {
    const backendUrl = window.backendHost + '/stub';
    fetch(backendUrl)
      .then(results => {
        return results.json();
      })
      .then(data => {
        this.setState({ data: data, isstub: true });
      });
  }

  handleText(e) {
    const value = e.target.value;
    const backendUrl = window.backendHost + '/parse';
    fetch(backendUrl, {
      method: 'post',
      mode: 'cors',
      body: value
    })
      .then(results => {
        return results.json()
      })
      .then(data => {
        console.log(data);
        this.setState({ data: data, isstub: false });
      });
  };

  textCallback(e) {
    e.persist();
    this.debouncedParse(e);
  }

  render() {
    return (
      <MuiThemeProvider>
      <div className="CsvParser">
      <h2>Text parsing powered by AI</h2>
      <b>Have a document that should be a spreadsheet?  We use artificial intelligence to figure out the structure, clean
        it up, and let you download it as a csv or xlsx file.  It's an online, intelligent version of the text-to-columns 
        function in a spreadsheet.</b>
        <br></br><br></br>
        <b>To get started paste the raw data below:</b>
        <br></br><br></br>
      <Paper>
        <TextField
          id="outlined-textarea"
          floatingLabelText="Enter text here"
          fullWidth={true}
          multiLine={true}
          rows={5}
          variant="outlined"
          margin="normal"
          onChange={this.textCallback.bind(this)}
        />
        </Paper>
        <br></br>
        <ParserResults 
          data={this.state.data}
          isstub={this.state.isstub} 
        />
      </div>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(CsvParser);
