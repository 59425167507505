import * as React from 'react';

import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from 'material-ui/Table';
// import { MuiThemeProvider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
    header: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    }
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});


class SimpleTable extends React.Component {
  constructor(props) {
    super(props);
    const { classes } = props;
    this.classes = classes;
  }
  
  render() {
    const customColumnStyle = { "paddingTop": "2px", "paddingBottom": "2px" };
    const customHeaderStyle = { "backgroundColor": "theme.palette.common.black",
                                "color": "theme.palette.common.white" };

    return (
      //<MuiThemeProvider>
        <Table className={this.classes.table}>
          <TableHeader>
            <TableRow>
              {(this.props.data.header).map((header, index) => {
                return (
                  <TableHeaderColumn
                    key={"header" + index} 
                    //style={customHeaderStyle}
                    >
                      {header.name}
                  </TableHeaderColumn>
                );
              })};
          </TableRow>
          </TableHeader>
          <TableBody>
            {(this.props.data.body).map((row, rowindex) => {
              return (
                <TableRow 
                  key={"bodyrow"+rowindex}
                  //style={customColumnStyle}
                  className={this.classes.row}
                >
                  {(row).map((col, colindex) => {
                    return (
                      <TableRowColumn 
                        key={"rowcol" + rowindex + "-" + colindex}
                        style={customColumnStyle}
                      >
                        {col.val}
                      </TableRowColumn>
                    );
                  })};
              </TableRow>
              );
            })};
        </TableBody>
        </Table>
      //</MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(SimpleTable);